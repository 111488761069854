@import "./variable";

@mixin spread-map($map: ()) {
  @each $key, $value in $map {
    #{$key}: $value;
  }
}

:root {
  @include spread-map($theme-map-light);
}
:root.light {
  @include spread-map($theme-map-light);
}
