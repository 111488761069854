/*
  Returns a CSS variable property specific to themeing.
  Accepts an optional fallback, and optional map.
  - SCSS: color: theme-var($--theme-primary, blue)
  - CSS:  color: var(--theme-primary, blue)
  Throws error if the variable not part of the map
*/
@function theme-var($key, $fallback: null, $map: $theme-map-light) {
  @if not map-has-key($map, $key) {
    @error "key: '#{$key}', is not a key in map: #{$map}";
  }
  @if ($fallback) {
    @return var($key, $fallback);
  } @else {
    @return var($key);
  }
}

$--theme-background: --theme-background;
$--white-background: --white-background;
$--primary-text: --primary-text;
$--secondary-background: --secondary-background;
$--white-20: --white-20;
$--black-80: --black-80;
$--black-40: --black-40;
$--black-100: --black-100;
$--cta-background: --cta-background;
$--negative-cta-background: --negative-cta-background;
$--speak-box: --speak-box;
$--white-1: --white-1;

// Override the light theme
$theme-map-dark: (
  $--theme-background: #0f1013,
  $--secondary-background: #3f4042,
  $--white-background: #fff,
  $--primary-text: #fff,
  $--white-20: rgba(255, 255, 255, 0.2),
  $--black-80: rgba(0, 0, 0, 0.8),
  $--black-40: rgba(0, 0, 0, 0.4),
  $--black-100: rgb(0, 0, 0),
  $--speak-box: rgba(15, 16, 19, 0.66),
  $--cta-background: #3beab5,
  $--negative-cta-background: #d9534f,
  $--white-1: #fff,
);

$theme-map-light: (
  $--theme-background: #fff,
  $--secondary-background: #3f4042,
  $--white-background: #fff,
  $--primary-text: #000,
  $--white-20: rgba(255, 255, 255, 0.2),
  $--black-80: rgba(0, 0, 0, 0.8),
  $--black-40: rgba(0, 0, 0, 0.4),
  $--black-100: rgb(0, 0, 0),
  $--speak-box: rgba(15, 16, 19, 0.66),
  $--cta-background: #3beab5,
  $--negative-cta-background: #d9534f,
  $--white-1: #fff,
);
